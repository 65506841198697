
@import "./variables.scss";

.accountPage{
margin-top:40px;

/* Media Query for low resolution  Phone */
@media (min-width: $breakpoint-phone) and (max-width: $breakpoint-phone-max-width) {
  margin-left: 50px;
  padding:15px;

}

/* Media Query for low resolution  Tablets, Ipads portrait*/
@media (min-width: $breakpoint-min-width-tablet-portrait) and (max-width: $breakpoint-max-width-tablet-portrait) {
    margin-left: 60px;
    padding:15px;
}


/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: $breakpoint-min-width-tablet) and (max-width: $breakpoint-max-width-tablet) {
    margin-left: 60px;
    padding:15px;
}


  
}