@import "./variables.scss";
.uhda-card{
    max-width:320px;
    height:280px;
    
    /* Media Query for low resolution  Phone */
@media (min-width: $breakpoint-phone) and (max-width: $breakpoint-phone-max-width) {
    
    margin-left: 30px;
    max-width:320px
 
}


/* Media Query for low resolution  Tablets, Ipads portrait*/
@media (min-width: $breakpoint-min-width-tablet-portrait) and (max-width: $breakpoint-max-width-tablet-portrait) {
    margin-left: 35px;
    
}


/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: $breakpoint-min-width-tablet) and (max-width: $breakpoint-max-width-tablet) {
    margin-left: 30px;
    max-width:320px;
   
    
}
}
.studies-grid{
    
    margin: 0; 
    max-width: '100%';

    
   
}


.uhda-card-forms{
    //max-width:250px;
    
    /* Media Query for low resolution  Phone */
@media (min-width: $breakpoint-phone) and (max-width: $breakpoint-phone-max-width) {
    
    margin-left: 30px;
    max-width:280px
 
}


/* Media Query for low resolution  Tablets, Ipads portrait*/
@media (min-width: $breakpoint-min-width-tablet-portrait) and (max-width: $breakpoint-max-width-tablet-portrait) {
    margin-left: 35px;
    
}


/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: $breakpoint-min-width-tablet) and (max-width: $breakpoint-max-width-tablet) {
    margin-left: 30px;
    max-width:280px;
   
    
}
}